@import '../../../styles/customMediaQueries.css';

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 56px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
  @media (--viewportMedium) {
    box-shadow: none;
    position: relative;
    width: 100%;
    padding: 0;
  }
}

.avatar {
  /* Layout */
  flex-shrink: 0;
}

.greeting {
  /* Font */
  composes: h1 from global;
  font-weight: var(--fontWeightSemiBold);
  font-size: 20px;
  line-height: 215.5%;
  margin-bottom: 1px;
  margin-top: 16px;
  color: var(--colorBlack);
}

.logoutButton {
  /* Position and dimensions */
  display: inline;

  /* Borders */
  border: none;

  /* Text size should be inherited */
  text-decoration: none;

  /* Logout font is smaller and gray since the action is not recommended. */
  font-weight: var(--fontWeightRegular);
  font-size: 14px;
  line-height: 20px;

  color: var(--colorGrey600);

  /* Position component */
  width: initial;
  margin: 4px 0 24px 0;
  padding: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.inbox {
  /* Font */
  composes: h2 from global;
  font-size: 14px;
  line-height: 100%;
  font-weight: var(--fontWeightRegular);
  color: var(--colorBlack);
  position: relative;

  margin-top: auto;
  margin-bottom: 13px;
  &:hover {
    color: var(--marketplaceColor);
  }
}

.navigationLink {
  /* Font */
  composes: h2 from global;
  font-size: 14px;
  line-height: 100%;
  font-weight: var(--fontWeightRegular);
  color: var(--colorBlack);

  /* Layout */
  margin-top: 0;
  margin-bottom: 11px;
  &:hover {
    color: var(--marketplaceColor);
  }
}
.currentPageLink {
  color: var(--marketplaceColorDark);
}

.notificationBadge {
  position: absolute;
  top: 4px;
  right: -26px;

  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.createNewListingLink {
  composes: buttonPrimary from global;
}

.authenticationGreeting {
  width: 100%;
  composes: h1 from global;
  margin-top: 0;
  margin-bottom: 24px;
  & > h1 {
    font-size: 24px;
    color: var(--colorBlack);
    font-weight: var(--fontWeightBold);
    margin: 0 0 20px 0;
  }
  & .authBtns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & .signupLink,
    & .loginLink {
      font-size: 16px;
      text-align: center;
      color: var(--colorBlack);
      font-weight: var(--fontWeightMedium);
      padding: 10px 20px;
      line-height: 150%;
      display: inline-block;
      border-radius: 4px;
      width: calc(100% / 2 - 10px);
    }
    & .signupLink {
      background-color: transparent;
      border: solid 1px var(--colorGrey300);
      color: var(--colorBlack);
      margin-right: 20px;
      &:hover {
        background-color: var(--colorBlack);
        border: solid 1px var(--colorBlack);
        color: var(--colorWhite);
        text-decoration: none;
        cursor: pointer;
      }
    }
    & .loginLink {
      background-color: var(--marketplaceColor);
      border: solid 1px var(--marketplaceColor);
      color: var(--colorWhite);
      &:hover {
        text-decoration: none;
        cursor: pointer;
        background-color: var(--marketplaceColorDark);
        border: solid 1px var(--marketplaceColorDark);
        color: var(--colorWhite);
      }
    }
  }
}
.browseSec {
  width: 100%;
  & > h2 {
    font-size: 20px;
    color: var(--colorBlack);
    font-weight: var(--fontWeightSemiBold);
    margin: 0 0 10px 0;
  }
  & > ul {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    & > li {
      width: calc(100% / 2 - 10px);
      margin-right: 20px;
      font-size: 16px;
      @media (--viewportMedium) {
        font-size: 15px;
      }
      &:nth-child(2n) {
        margin-right: 0;
      }
      & > a {
        padding: 10px 0;
        display: inline-flex;
        width: 100%;
        color: var(--matterColorDark);
        &:hover {
          color: var(--marketplaceColor);
          text-decoration: none;
        }
        & > svg {
          fill: none;
          margin-right: 10px;
          width: 24px;
        }
        & > span {
          width: calc(100% - 24px);
        }
      }
    }
  }
}
.footerSocialIcon {
  margin-top: 30px;
  width: 100%;
  & > ul {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    & > li {
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
      & > a {
        &.instagram,
        &.email {
          & > svg {
            width: 22px;
            height: 22px;
          }
        }
        &.linkedin {
          & > svg {
            width: 26px;
            height: 26px;
          }
        }
        & > svg {
          width: 24px;
          height: 24px;
          & path {
            fill: var(--colorGrey600);
          }
        }
      }
    }
  }
  & > p {
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 20px;
  }
}
.authenticationLinks {
  white-space: nowrap;
}

.signupLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}
.loginLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.currentPage {
  color: var(--colorBlack);

  /* black left border */
  border-left: 5px solid black;
  margin-left: -24px;
  padding-left: 19px;
}

.spacer {
  width: 100%;
  height: 124px;
  @media (--viewportMedium) {
    height: 40px;
  }
}
.dashboardSidebar {
  background-color: var(--colorWhite);
  width: 100%;
  padding: 10px;
  overflow-y: auto;
  height: 100%;
  & .faqCard {
    background-color: var(--colorGrey50);
    margin-bottom: 10px;
    padding: 0 5px;
    &.activeFAQ {
    }
    & .faqTitle {
      cursor: pointer;
      font-weight: var(--fontWeightSemiBold);
      color: var(--colorGrey900);
      font-size: 14px;
      line-height: 100%;
      padding: 10px 16px;
      & .faqIcon {
        margin-right: 10px;
      }
      & .faqHeading {
      }
    }
    & .faqDescription {
      padding: 10px 16px;
      font-weight: var(--fontWeightRegular);
      color: var(--colorGrey900);
      font-size: 14px;
      line-height: 100%;
      background-color: var(--colorWhite);
      & .subContent {
        display: flex;
        flex-direction: column;
        & > a {
          width: 100%;
          align-items: center;
          display: inline-flex;
          padding: 10px 0;
          font-weight: var(--fontWeightRegular);
          color: var(--colorGrey900);
          font-size: 14px;
          line-height: 120%;
          & > span {
            min-width: 24px;
            width: 24px;
            height: 24px;
            border-radius: 100px;
            overflow: hidden;
            margin-right: 10px;
            & > img {
              max-width: 100%;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}
