@import '../../../styles/customMediaQueries.css';

.root {
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  display: block !important;
}

.staticPage {
  & .topbar {
    padding: 0 10px;
    /* top: 10px; */
    &.sticky {
      /* top: 10px; */
    }
  }
  & .main {
    padding-top: 0px;
  }
}
.topbar {
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: var(--zIndexTopbar);
  transition: var(--transitionStyle);
}

.main {
  display: block;
  padding-top: 60px;
  @media (--viewportMedium) {
    padding-top: 70px;
  }
}

.dashboardPage {
  & .topbar {
    padding: 0;
    position: sticky;
  }
  & .main {
    padding-top: 0;
  }
}
