@import '../../styles/customMediaQueries.css';
.modal {
  background: rgb(255, 255, 255);
  width: 94%;
  max-width: 500px;
  border-radius: 20px;
  font-size: 15px;
  margin: 30px auto;
  & .header {
    width: 100%;
    border-bottom: 1px solid var(--colorGrey100);
    font-size: 18px;
    text-align: left;
    padding: 15px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  & .content {
    width: 100%;
    padding: 15px 24px 24px;
    & > img {
      max-width: 100%;
      width: 50%;
      margin-top: 14px;
      border-radius: 10px;
    }
  }
  & .actions {
    width: 100%;
    padding: 15px;
    margin: auto;
    text-align: center;
  }
  & .close {
    cursor: pointer;
    display: block;
    padding: 0;
    line-height: 16px;
    font-size: 30px;
    font-weight: var(--fontWeightRegular);
    background: #ffffff;
    border-radius: 100px;
    border: none;
    outline: none;
    transition: var(--transitionStyle);
    &:hover {
      transition: var(--transitionStyle);
      color: var(--marketplaceColor);
    }
  }
}
.image {
  display: flex;
  justify-content: center;
}
