@import '../../styles/customMediaQueries.css';

.dashboardSidebar {
  width: 100%;
  background-color: var(--colorWhite);
  display: flex;
  flex-direction: column;
  z-index: var(--zIndexTopbar);
  box-shadow: var(--boxShadowFilterButton);
  margin-top: 15px;
  @media (--viewportLarge) {
    box-shadow: none;
    max-width: 250px;
    margin-top: 0px;
  }
  &.topicPageSidebar {
    position: sticky;
    top: var(--topbarHeightDesktop);
    align-self: flex-start;
    z-index: 0;
  }
  & .upcomingEvent {
    padding: 16px;
    width: 100%;
    display: inline-flex;
    align-items: flex-start;
    color: #8e8e8e;
    transition: var(--transitionStyle);
    font-size: 16px;
    font-weight: var(--fontWeightRegular);
    &:not(:last-child) {
      border-bottom: solid 1px var(--colorGrey100);
    }
    &:hover,
    &.active {
      cursor: pointer;
      text-decoration: none;
      transition: var(--transitionStyle);
      background-color: var(--marketplaceColor);
      color: var(--colorWhite);
      &:not(:last-child) {
        border-bottom: solid 1px var(--marketplaceColor);
      }
      & > svg {
        transition: var(--transitionStyle);
        & path {
          transition: var(--transitionStyle);
          stroke: var(--colorWhite);
        }
      }
      & .faqCard {
        & .faqTitle {
          & .faqIcon {
            & > svg {
              & path {
                fill: var(--colorWhite);
              }
            }
          }
        }
      }
    }
    & > svg {
      transition: var(--transitionStyle);
      margin-right: 8px;
      width: 20px;
      min-width: 20px;
      height: 20px;
      & path {
        transition: var(--transitionStyle);
        stroke-width: 2px;
        stroke: var(--colorGrey600);
      }
    }
  }

  & .faqCard {
    transition: var(--transitionStyle);

    &.activeFAQ {
    }

    & .faqTitle {
      width: 100%;
      display: inline-flex;
      align-items: center;
      font-size: 16px;
      font-weight: var(--fontWeightRegular);
      cursor: pointer;
      & .faqIcon {
        margin-right: 10px;
        & > svg {
          & path {
            fill: var(--colorGrey600);
          }
        }
      }

      & .faqHeading {
        font-size: 16px;
        font-weight: 400;
      }
    }

    & .faqDescription {
      padding: 10px 16px;
      font-weight: var(--fontWeightRegular);
      color: var(--colorGrey900);
      font-size: 14px;
      line-height: 100%;

      & .subContent {
        display: flex;
        flex-direction: column;

        & > a {
          width: 100%;
          display: inline-flex;
          align-items: center;
          padding: 10px 0;
          font-weight: var(--fontWeightRegular);
          color: var(--colorGrey900);
          font-size: 14px;
          line-height: 120%;

          & > span {
            min-width: 24px;
            width: 24px;
            height: 24px;
            border-radius: 100px;
            overflow: hidden;
            margin-right: 10px;

            & > img {
              max-width: 100%;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}
.mobileMenuToggleWrapper {
  position: relative;
  width: 100%;
  padding: 24px;
  @media (--viewportLarge) {
    padding: 0;
  }

  & .backBtn {
    /* background-color: var(--marketplaceColor);
    width: 30px;
    height: 30px;
    border-radius: 100px; */
    display: inline-flex;
    align-items: center;
    justify-content: center;
    & > svg {
      width: 18px;
      height: 18px;
      & path {
        /* fill: var(--colorWhite); */
      }
    }
  }
  & .mobileMenuToggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 16px;
    line-height: 120%;
    font-weight: var(--fontWeightSemiBold);
    color: var(--colorGrey900);
    & > svg {
      width: 20px;
      height: 20px;
    }
    &.expanded {
      & > svg {
        width: 14px;
        height: 14px;
      }
    }
  }
}
