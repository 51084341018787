.hiddenFileInput {
  position: fixed;
  visibility: hidden;
}

.wrapper {
  /* border: 1px solid #dedede; */
  border-radius: 7px;
  padding: 0px;
  & .fileIcon {
    text-align: center;
  }
}

.fileWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.addFileButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--marketplaceColor);
  padding: 5px 15px;
  font-weight: var(--fontWeightSemiBold);
  font-size: 14px;
  line-height: 100%;
  color: white;
  border-radius: var(--borderRadius);
  cursor: pointer;
  min-height: 45px;
  border-radius: 4px;

  &:hover {
    background-color: var(--marketplaceColorDark);
  }
  & > svg {
    width: 24px;
    height: 24px;
    & path {
      fill: var(--colorWhite);
    }
  }
}

.uploadImage {
  width: 30px;
  padding: 5px;
}

.fileIcon {
  padding: 0 10px;
  font-size: 14px;
  line-height: 120%;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  & > span {
    > svg {
      width: 18px;
      height: 18px;
      fill: white;
      position: relative;
    }
  }
}

.icon {
  font-size: 40px !important;
}

.deleteIcon {
  position: absolute;
  top: -10px;
  right: 10px;
  cursor: pointer;
  color: var(--matterColor) !important;
  transform: scale(0.85);

  &:hover {
    color: red !important;
  }
}

.label {
  font-size: 15px;
  color: var(--colorGrey600);
  padding: 0;
  margin-bottom: 8px;
  margin-top: 0;
  text-align: center;
}

.fileLabel {
  line-height: 18px;
  margin: 0;
  text-align: center;
}
