@import '../../styles/customMediaQueries.css';
.videoContentWrapper {
  position: relative;
  & > img,
  & vm-player {
    /* position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; */
    max-width: 100%;
    width: 100%;
    z-index: 0;
  }
}
.videoPoster {
  position: relative;
  & > img {
    /* position: absolute;
    top: 0;
    left: 0; */
    max-width: 100%;
    width: 100%;
    z-index: 0;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}
.overlayIcon {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  & > svg {
    width: 40px;
    height: 40px;
    background-color: var(--colorWhite);
    border-radius: 100px;
    padding: 3px;
    box-shadow: var(--boxShadowPopup);
    @media (--viewportMedium) {
      width: 60px;
      height: 60px;
    }
    & path {
      stroke: var(--marketplaceColor);
      stroke-width: 1px;
    }
  }
}
.guideVideoPage {
  min-height: calc(100vh - 70px);
  background-color: var(--colorWhite);
  font-size: 15px;
  color: #1a1d1f;
  display: flex;
  align-items: flex-start;
  width: 100%;
  position: relative;
  z-index: 1;
  flex-direction: column;
  @media (--viewportLarge) {
    padding: 0px;
    flex-direction: row;
    align-items: stretch;
    margin: 0px auto;
  }
  & .centerContent {
    background-color: var(--colorWhite);
    padding: 24px;
    border-left: solid 1px var(--colorGrey100);
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 24px;
    @media (--viewportLarge) {
      flex-direction: row;
      padding: 24px;
    }
    @media (--viewportSLarge) {
      gap: 40px;
      padding: 40px;
    }
    & .mainContent {
      width: 100%;
      @media (--viewportLarge) {
        width: calc(100% - 300px);
      }
      @media (--viewportSLarge) {
        width: calc(100% - 370px);
      }
    }
    & .bookingPanel {
      width: 100%;
      padding: 24px;
      border-radius: var(--borderRadiusLarge);
      background-color: var(--colorWhite);
      border: solid 1px var(--colorGrey100);
      box-shadow: var(--boxShadowLight);
      @media (--viewportLarge) {
        padding: 16px;
        position: sticky;
        top: calc(var(--topbarHeightDesktop) + 24px);
        width: 300px;
      }
      @media (--viewportSLarge) {
        width: 370px;
      }
    }
  }
}

.guideVideoWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  @media (--viewportMedium) {
    flex-direction: row;
  }

  & .guideVideoSidebar {
    width: 100%;
    background-color: var(--colorWhite);
    padding: 24px;
    border-radius: var(--borderRadiusLarge);
    margin-bottom: 24px;

    @media (--viewportMedium) {
      max-width: 358px;
      margin-bottom: 0;
    }

    & > h1 {
      font-size: 24px;
      line-height: 120%;
      font-weight: var(--fontWeightBold);
      color: var(--colorGrey600);
      margin: 0 0 25px 0;
      padding: 0;
    }

    & .sidebarBlock {
      width: 100%;
      margin: 0 auto;
      position: relative;
      z-index: 1;

      &:not(:last-child) {
        margin-bottom: 24px;
      }

      & > h2 {
        line-height: 130%;
        font-weight: var(--fontWeightBold);
        color: var(--colorBlack);
        font-size: 14px;
        line-height: 120%;
        margin: 0 0 15px 0;
        padding: 0;
      }

      & .faqCard {
        cursor: pointer;
        transition: background-color 0.3s ease;
        background-color: transparent;
        transition: all ease 0.5s;

        &:not(:last-child) {
          margin-bottom: 8px;
        }

        &.activeFAQ {
          transition: all ease 0.5s;

          & .faqTitle {
            background-color: var(--marketplaceColor);

            & .faqHeading {
              color: var(--colorWhite);
            }
          }
        }

        & .faqTitle {
          padding: 14px 20px;
          font-weight: var(--fontWeightMedium);
          display: flex;
          align-items: center;
          background-color: #e6e6e6;
          border-radius: var(--borderRadiusLarge);

          & .faqHeading {
            font-size: 14px;
            color: var(--colorGrey900);
            font-weight: var(--fontWeightMedium);
          }

          & .faqIcon {
            font-size: 27px;
            line-height: 100%;
            font-weight: 400;
            transition: all ease 0.5s;
            color: var(--colorGrey400);
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;
          }
        }

        & .faqDescription {
          color: var(--colorGrey600);
          font-weight: var(--fontWeightRegular);
          font-size: 16px;
          line-height: 150%;
          padding: 0 20px;

          & .subMenu {
            display: flex;
            flex-direction: column;
            padding: 10px 0;

            & > a {
              font-size: 14px;
              font-weight: var(--fontWeightRegular);
              color: var(--colorGrey600);
              text-decoration: underline;
              display: inline-flex;
              align-items: center;
              justify-content: flex-start;
              padding: 4px 20px;
              transition: var(--transitionStyle);

              &:hover {
                transition: var(--transitionStyle);
                color: var(--marketplaceColor);
              }

              & > svg {
                margin-right: 8px;
              }
            }
          }
        }
      }
    }
  }

  & .guideVideoContent {
    width: 100%;
    display: flex;
    flex-direction: column;

    & .videoWrapper {
      border-radius: var(--borderRadiusLarge);
      overflow: hidden;
      padding-bottom: 60%;
      position: relative;

      & > img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      & > div {
        width: 100% !important;
        height: 100% !important;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
      }

      & > video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      & > iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.aboutMenopause {
  margin-top: 24px;

  @media (--viewportSLarge) {
    margin-top: 32px;
  }

  & > h2 {
    margin: 0 0 15px 0;
    padding: 0;
    line-height: 120%;
    padding: 0;
    margin: 0 0 10px 0;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  & > p {
    font-size: 16px;
    line-height: 150%;
    font-weight: var(--fontWeightRegular);
    color: var(--colorGrey600);
    margin: 0 0 10px 0;
    padding: 0;
  }
}

.specialistSec {
  background-color: var(--colorGrey50);
  border-radius: var(--borderRadiusLarge);
  padding: 24px;
  margin-top: 24px;

  @media (--viewportSLarge) {
    margin-top: 32px;
  }

  & .aboutSpecialist {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    & .specialistImg {
      width: 70px;
      height: 70px;
      border-radius: 100px;
      overflow: hidden;

      & > img {
        max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    & .specialistInfo {
      width: calc(100% - 70px);
      padding-left: 15px;

      & > h2 {
        margin: 0 0 10px 0;
        padding: 0;
        font-weight: var(--fontWeightSemiBold);
        line-height: 120%;
        font-size: 16px;
        color: var(--marketplaceColorSecondary);
      }

      & > p {
        font-size: 16px;
        font-weight: var(--fontWeightRegular);
        color: #5d5a88;
        line-height: 150%;
        margin: 0;
        padding: 0;
      }
    }
  }

  & .specialistDetails {
    & > p {
      font-size: 16px;
      font-weight: var(--fontWeightRegular);
      color: var(--colorGrey900);
      line-height: 150%;
      margin: 0;
      padding: 0;
      width: 100%;

      @media (--viewportSLarge) {
        max-width: 80%;
      }
    }
  }
}

.mobileMenuToggleWrapper {
  position: relative;
  width: 100%;

  & .mobileMenuToggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 16px;
    line-height: 120%;
    font-weight: var(--fontWeightSemiBold);
    color: var(--colorGrey900);

    & > svg {
      width: 20px;
      height: 20px;
    }

    &.expanded {
      & > svg {
        width: 14px;
        height: 14px;
      }
    }
  }
}

.sidebarBlock {
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  margin-top: 10px;
  @media (--viewportMedium) {
    margin-top: 0;
  }

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  & > h2 {
    line-height: 130%;
    font-weight: var(--fontWeightsemiBold);
    color: var(--colorGrey900);
    font-size: 16px;
    line-height: 120%;
    margin: 0 0 15px 0;
    padding: 0;
  }

  & .faqCard {
    cursor: pointer !important;
    transition: background-color 0.3s ease;
    background-color: transparent;
    transition: all ease 0.5s;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    &.activeFAQ {
      transition: all ease 0.5s;

      & .faqTitle {
        background-color: var(--marketplaceColor);

        & .faqHeading {
          color: var(--colorWhite);
        }
      }
    }

    & .faqTitle {
      padding: 14px 20px;
      font-weight: var(--fontWeightMedium);
      display: flex;
      align-items: center;
      background-color: var(--colorGrey50);
      border-radius: var(--borderRadiusLarge);

      & .faqHeading {
        font-size: 14px;
        color: var(--colorGrey900);
        font-weight: var(--fontWeightMedium);
      }

      & .faqIcon {
        font-size: 27px;
        line-height: 100%;
        font-weight: 400;
        transition: all ease 0.5s;
        color: var(--colorGrey400);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        & > svg {
          & path {
            stroke: var(--colorGrey600);
          }
        }
      }
    }

    & .faqDescription {
      color: var(--colorGrey600);
      font-weight: var(--fontWeightRegular);
      font-size: 16px;
      line-height: 150%;
      padding: 0 20px;

      & .subMenu {
        display: flex;
        flex-direction: column;
        padding: 10px 0;

        & > a {
          font-size: 14px;
          font-weight: var(--fontWeightRegular);
          color: var(--colorGrey600);
          text-decoration: underline;
          display: inline-flex;
          align-items: center;
          justify-content: flex-start;
          padding: 4px 20px;
          transition: var(--transitionStyle);

          &:hover {
            transition: var(--transitionStyle);
            color: var(--marketplaceColor);
          }

          & > svg {
            margin-right: 8px;
          }
        }
      }
    }
  }
}
.iconSpinner {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20%;

  & > svg {
    stroke: #fff !important;
  }
}

.playerSec {
  display: flex;
  flex-direction: column;
  position: relative;

  & > iframe {
    height: 100% !important;
  }
}

.isPlayingSec {
  display: flex;
  flex-direction: column;
  position: relative;

  & > iframe {
    height: 1000% !important;
  }
}

.playerControls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 2px;
  background-color: var(--colorWhite);
  border-radius: var(--borderRadiusLarge);
  max-width: 80%;
  width: 100%;
  margin: 0 auto;
  left: 0;
  right: 0;
  padding: 20px;
  z-index: 10;
  /* Ensure controls appear above video */

  @media (--viewportMedium) {
    max-width: 500px;
  }

  & .btnsGroup {
    display: inline-flex;
    align-items: center;
    gap: 20px;

    & .btn {
      border: none;
      padding: 0;
      cursor: pointer;
      outline: none;
      background: transparent;
      /* Ensure background doesn’t interfere */
      z-index: 11;
      /* Ensure buttons are clickable */

      &:hover,
      &:focus,
      &:active {
        cursor: pointer;
        outline: none;
        border: none;
        box-shadow: none;
      }

      & > svg {
        width: 22px;
        height: 22px;
        pointer-events: none;
        /* Ensure click passes to button, not SVG */
      }
    }
  }
}

.fullVideoWrapper {
  padding-bottom: 60% !important;
}

.imageWrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.thumbnailImage {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
}

.playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.6);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.playButton:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}
.loader::before,
.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #fff;
  animation: prixClipFix 2s linear infinite;
}
.loader::after {
  transform: rotate3d(90, 90, 0, 180deg);
  border-color: #ee8b51;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  75%,
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
}
.fullscreenTrigger {
  position: absolute;
  bottom: 0;
  left: 0;
}
.modalContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &.rotatedVideo {
    & > vm-player {
    }
  }
}
