@import '../../styles/customMediaQueries.css';

.root {
  margin-top: 24px;
}

@media (--viewportMedium) {
  .root {
    margin-top: 24px;
  }
}

.disabled {
  opacity: 0.5;
}

.modalSubTitle {
  composes: marketplaceModalSubTitleStyles from global;
}
.sectionContainer {
  margin-bottom: 35px;

  @media (--viewportMedium) {
    margin-bottom: 56px;
  }
}

.formRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
}

.radioButtonRow {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 14px;
  white-space: nowrap;
}

.radioButtonRow > :first-child {
  margin-right: 24px;
}

.selectCountry {
  margin-bottom: 24px;
  & > label {
    /* text-transform: uppercase; */
    color: var(--colorGrey300);
    line-height: 20px;
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    margin: 0 0 6px 0;
  }
  & > select {
    box-shadow: none;
  }
}

.error {
  composes: marketplaceModalPasswordMargins from global;
}

.termsText {
  composes: marketplaceModalHelperText from global;
  margin-bottom: 12px;
  text-align: center;

  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.termsLink {
  composes: marketplaceModalHelperLink from global;
  text-decoration: underline;
  color: var(--marketplaceColor);
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.bankDetailsStripeField p {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 17px;
    margin-bottom: 15px;
  }
}

.missingStripeKey {
  color: var(--colorFail);
}

.accountInformationTitle {
  margin: 0;
}

.savedCountry {
  padding-top: 6px;
  padding-bottom: 0px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    padding-top: 4px;
    padding-bottom: 4px;
    margin-bottom: 16px;
  }
}

.savedInformation {
  margin-bottom: 24px;
}

.savedBankAccount {
  composes: marketplaceInputStyles from global;
  color: var(--colorGrey700);
  text-align: left;

  &:hover {
    cursor: text;
    text-decoration: none;
  }
}

.termsAndConditions {
  display: flex;
  margin-bottom: 10px;
  font-size: 13px;
}
