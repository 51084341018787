@import '../../styles/customMediaQueries.css';

.profileVerification {
  position: fixed;
  top: 80px;
  right: 0;
  left: 0;
  z-index: 1;
  max-width: calc(100% - 20px);
  width: 100%;
  padding: 10px 20px;
  border-radius: 6px;
  background: var(--marketplaceColor);
  color: var(--colorWhite);
  box-shadow: var(--boxShadow);
  margin: 0 auto;
  font-size: 15px;
  font-weight: 400;
  @media (--viewportLarge) {
    top: 82px;
    max-width: var(--contentMaxWidth);
  }
  & > span {
    color: var(--colorWhite);
    top: 10px;
    position: absolute;
    right: 10px;
    font-size: 30px;
    font-weight: 300;
  }
}
