.custom-select__control {
  border-color: #e6e6e6;
  border-radius: 6px;
  box-shadow: none;
  height: 46px;
  transition: all 0.3s ease-in-out;
  &:hover {
    border-color: #ee8b51;
  }
  &:focus {
    outline: none;
    box-shadow: none;
    border-color: #ee8b51;
  }
}

.custom-select__menu {
  border-radius: 6px;
  margin-top: -1px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

.custom-select__option {
  font-weight: normal;
  color: #212121;
  transition: all 0.3s ease-in-out;
  &.custom-select__option--is-selected {
    background-color: #fff;
    color: #333;
  }
  &:hover {
    background-color: #eeeeee;
  }
}

.custom-select__control--is-focused .custom-select__indicators {
  color: #ee8b51;
}

.custom-select__indicator-separator {
  display: none;
}

.custom-select__indicator {
  color: #e6e6e6;
  transition: all 0.3s ease-in-out;
}

.custom-select__indicator:hover {
  color: #ee8b51;
  transition: all 0.3s ease-in-out;
}

.custom-select--is-open .custom-select__control {
  border-radius: 6px 6px 0 0;
  border-bottom-color: #e6e6e6;
}

.custom-select--is-open .custom-select__menu {
  border-radius: 0 0 6px 6px;
  margin-top: 0;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  &::before {
    position: absolute;
    content: '';
    top: -7px;
    left: 14px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #e6e6e6;
  }
}

@keyframes scaleIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.custom-select__menu--is-open {
  animation-name: scaleIn;
  animation-duration: 0.2s;
}
