@import '../../styles/customMediaQueries.css';

.fieldRoot {
}

/**
 * Mobile margins mean that labels, inputs (incl separate borders) get margins,
 * but calendar popup doesn't.
 */
.mobileMargins {
  width: 100%;
  margin: 0;
}
