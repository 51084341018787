@import '../../styles/customMediaQueries.css';
.supportWidgetWrapper {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: var(--marketplaceColor);
  color: var(--colorWhite);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
  cursor: pointer;
  @media (--viewportMedium) {
    display: flex;
  }
}

.supportWidgetFormWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  max-width: 300px;
  width: 100%;
  background-color: var(--colorWhite);
  color: var(--marketplaceColor);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 70px;
  right: 20px;
  z-index: 1000;
  overflow: hidden;
  transition: all 0.1s ease;
  @media (--viewportMedium) {
    max-width: 400px;
  }
}

.supportWidgetFormHeader {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
  & .supportIcon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 14px;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    border: solid 10px var(--colorGrey50);
    background-color: var(--colorWhite);
    box-sizing: content-box;
    & > svg {
      & path {
        stroke: var(--colorGrey500);
      }
    }
  }
  & > h4 {
    font-size: 20px;
    line-height: 120%;
    font-weight: var(--fontWeightBold);
    color: var(--colorGrey900);
    padding: 0;
    margin: 0 0 5px 0;
  }

  & > p {
    font-size: 16px;
    line-height: 140%;
    font-weight: var(--fontWeightRegular);
    color: var(--colorGrey600);
    padding: 0;
    margin: 0 0 10px 0;
  }
}

.supportWidgetFormDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: var(--black);
  width: 100%;
  & .supportFormRow {
    width: 100%;
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  & .supportWidgetFormItemLabel {
    color: black;
    font-weight: var(--fontWeightSemiBold);
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 120%;
    margin: 0;
    padding: 0 0 4px 0;
  }

  & .supportWidgetFormItem {
    color: black;
    min-height: 45px;
    text-decoration: none;
    background-color: var(--colorWhite);
    border: solid 1px var(--colorGrey100);
    border-radius: var(--borderRadiusMedium);
    padding: 10px 20px;
    font-weight: var(--fontWeightRegular);
    font-size: 16px;
    line-height: 120%;
  }
}

.open {
  opacity: 1;
}

.closed {
  opacity: 0;
  pointer-events: none;
}

.helpText {
  font-size: 18px;
  line-height: 20px;
  margin: 0;
  padding: 0;
}
