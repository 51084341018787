@import '../../../styles/customMediaQueries.css';

.fixedWidthContainer {
  max-width: var(--contentMaxWidthPages);
  width: 100%;
  margin: 0 auto;
}

.fullWidthContainer {
  width: 100%;
  background-color: var(--colorWhite);
  box-shadow: 0px 2px 4px rgb(0 0 0 / 27%);
  transition: var(--transitionStyle);

  &.dashboardHeader {
    box-shadow: none;
  }

  & .root {
    padding: 10px 16px;
    height: 60px;

    @media (--viewportMedium) {
      height: 70px;
    }

    @media (--viewportLarge) {
      padding: 14px 24px;
    }

    @media (--viewportLargeWithPaddings) {
      padding: 14px 30px;
    }

    & .navLink {
      color: var(--colorGrey900);
      font-size: 12px;
      line-height: 18px;
      margin-right: 8px;

      @media (--viewportLarge) {
        font-size: 13px;
        margin-right: 10px;
      }

      @media (--viewportMLarge) {
        font-size: 14px;
        line-height: 20px;
        margin-right: 20px;
      }

      @media (--viewportSLarge) {
        font-size: 16px;
        line-height: 20px;
        margin-right: 30px;
      }

      &:hover,
      &.active {
        color: var(--colorGrey900);
        text-decoration: none;

        &::after {
          width: 100%;
          transition: var(--transitionStyle);
        }
      }

      &::after {
        position: absolute;
        transition: var(--transitionStyle);
        bottom: 0;
        left: 0;
        width: 0;
        height: 1px;
        background-color: var(--colorGrey900);
        content: '';
      }
    }

    & .logoLink {
      & > svg {
        width: 100px;

        @media (--viewportLarge) {
          width: 120px;
        }

        @media (--viewportMLarge) {
          width: 140px;
        }

        & > path {
          fill: var(--marketplaceColorSecondary);

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            fill: var(--marketplaceColor);
          }
        }
      }
    }

    & .rightLinks {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      & .middleLinks {
        & .navLink {
          padding: 0;
          font-weight: var(--fontWeightBold);
          color: var(--marketplaceColorSecondary);

          &:hover,
          &:focus,
          &:active,
          &.active {
            color: var(--marketplaceColorSecondary);
          }

          &.signup {
            text-decoration: none;
            margin-top: 0;
            margin-bottom: 0;
            margin-right: 0;

            &::after {
              display: none;
            }

            & > svg {
              width: 24px;
              height: 24px;

              @media (--viewportLarge) {
                width: 30px;
                height: 30px;
              }

              @media (--viewportSLarge) {
                width: 36px;
                height: 36px;
              }
            }
          }
        }
      }

      & .languageButton {
        & > button {
          background-color: rgba(255, 255, 255, 0.5);
          border: solid 1px rgba(255, 255, 255, 0.5);
          border-radius: 100px;
          width: 24px;
          height: 24px;
          padding: 0px;
          display: inline-flex;
          justify-content: center;
          align-items: center;

          @media (--viewportLarge) {
            width: 30px;
            height: 30px;
          }

          @media (--viewportSLarge) {
            width: 36px;
            height: 36px;
          }

          &:hover,
          &:focus,
          &:active {
            background-color: rgba(255, 255, 255, 1);
            border: solid 1px rgba(255, 255, 255, 1);
          }

          & > svg {
            display: none;
          }

          & > span {
            margin-right: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

/* Desktop */
.root {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  border-radius: 4px;
  transition: var(--transitionStyle);

  &.staticPageHeader {
    padding: 10px 16px;

    @media (--viewportSLarge) {
      padding: 16px 24px;
    }

    &.stickNav {
      & .middleLinks {
        & .rightLinks {
          & .profileMenuLabel {
            & .avatar {
              border-color: var(--marketplaceColorSecondary);

              & > span {
                color: var(--marketplaceColorSecondary);
              }
            }
          }
        }
        & .programs {
          & > div {
            & > div {
              &:first-of-type {
                & div {
                  color: var(--colorGrey900);
                  & svg {
                    width: 12px;
                    height: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }

    & .logoLink {
      & > svg {
        width: 120px;

        @media (--viewportMLarge) {
          width: 170px;
        }
      }
    }

    & .middleLinks {
      & .rightLinks {
        & .profileMenuLabel {
          & .avatar {
            border-color: var(--colorWhite);

            & > span {
              color: var(--colorWhite);
            }
          }
        }
      }
    }
  }
}

.stickNav {
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadow);
  transition: var(--transitionStyle);

  & .navLink {
    color: var(--colorGrey900);

    &:hover,
    &.active {
      color: var(--colorGrey900);
      text-decoration: none;

      &::after {
        width: 100%;
        transition: var(--transitionStyle);
      }
    }

    &::after {
      position: absolute;
      transition: var(--transitionStyle);
      bottom: 0;
      left: 0;
      width: 0;
      height: 1px;
      background-color: var(--colorGrey900);
      content: '';
    }
  }

  & .logoLink {
    & > svg {
      width: 120px;

      @media (--viewportMLarge) {
        width: 170px;
      }

      & > path {
        fill: var(--marketplaceColor);
      }
    }
  }

  & .languageButton {
    & > button {
      background-color: var(--colorGrey50);
      border: solid 1px var(--colorGrey100);

      &:hover,
      &:focus,
      &:active {
        background-color: var(--colorGrey50);
        border: solid 1px var(--colorGrey100);
      }
    }
  }
}

/* logo */
.logoLink {
  transition: var(--transitionStyle);
}

.logoLink:hover {
  border-radius: 0;
}

/* Search */
.searchLink {
  flex-grow: 1;
  height: 100%;
  margin-right: 24px;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--colorGrey100);

  @media (--viewportLarge) {
    padding-left: 0;
  }
}

.search {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
  }
}

.topbarSearchWithLeftPadding {
  padding-left: 24px;
  height: var(--topbarHeightDesktop);

  @media (--viewportLarge) {
    padding-left: 34px;
  }
}

/* Create listing (CTA for providers) */
.createListingLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyle);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--marketplaceColor);

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  margin: 0;

  &:hover {
    color: var(--marketplaceColorDark);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

.topbarDesktopLabel {
  composes: textSmall from global;
  display: inline-block;
  text-decoration: inherit;
}

.createListing {
  composes: topbarDesktopLabel;
}

/* Inbox */
.inboxLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyle);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  height: 100%;
  padding: 0 12px 0 12px;
  margin: 0;

  &:hover {
    color: var(--colorBlack);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

.inbox {
  composes: topbarDesktopLabel;
  position: relative;
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: -3px;
  right: 0px;

  /* Style: red dot */
  background-color: var(--marketplaceColor);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  transition: var(--transitionStyle);
  outline: none;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0;

  &:hover {
    border-radius: 0;
    text-decoration: none;
  }

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  width: 52px;
  height: 52px;
}

.profileMenuContent {
  min-width: 320px;
  box-sizing: border-box;
  padding: 20px;
  right: 30px;
  left: auto !important;
  top: calc(100% + 18px);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  & > div {
    display: none;
  }

  & .profileMenuHead {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > h2 {
      font-weight: 600;
      font-size: 20px;
      line-height: 215.5%;
      margin: 0;
      color: var(--colorBlack);
    }

    & .avatar {
      width: 52px;
      height: 52px;

      & > span {
        font-size: 20px;
      }
    }
  }

  & .logoutButton {
    display: flex;
    align-items: center;
    justify-content: space-between;

    /* Text size should be inherited */
    text-decoration: none;

    /* Font is specific to this component */
    font-weight: var(--fontWeightRegular);
    font-size: 14px;
    line-height: 215.5%;
    letter-spacing: 0;

    /* Dimensions */
    position: relative;
    width: 100%;
    margin: 0;
    padding: 10px 0px;

    text-align: left;
    white-space: nowrap;
    transition: var(--transitionStyle);

    /* Borders */
    border: none;

    &:hover {
      cursor: pointer;
      color: var(--marketplaceColor);
      text-decoration: none;

      & .menuItemBorder {
        height: 100%;
        width: 6px;
        top: 0;
        background-color: var(--marketplaceColor);
      }
    }

    @media (--viewportMedium) {
      line-height: 16px;
      margin-bottom: 0;
    }
  }

  & > ul {
    min-width: 320px;

    & > li:not(:first-child) {
      border-bottom: solid 1px #faa31a;
    }

    & > li {
      /* &:first-child {
        margin-bottom: 24px;
      } */
      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.navLink {
  color: var(--colorWhite);
  transition: var(--transitionStyle);
  position: relative;
  padding: 6px 0;
  font-size: 14px;
  line-height: 18px;
  font-weight: var(--fontWeightRegular);
  margin-right: 10px;

  @media (--viewportMLarge) {
    margin-right: 20px;
    font-size: 14px;
    line-height: 21px;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover,
  &.active {
    transition: var(--transitionStyle);
    color: var(--colorWhite);
    /* font-weight: var(--fontWeightBold); */
    text-decoration: none;

    &::after {
      width: 100%;
      transition: var(--transitionStyle);
    }
  }

  &::after {
    position: absolute;
    transition: var(--transitionStyle);
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background-color: var(--colorWhite);
    content: '';
  }
}

/* Authentication */
.signupLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyle);

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--colorGrey700);

  &:hover {
    color: var(--colorBlack);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }
}

.loginLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyle);

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--colorGrey700);

  &:hover {
    color: var(--colorBlack);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }
}

.signup,
.login {
  composes: textSmall from global;

  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyle);
}

.profileSettingsLink,
.yourListingsLink {
  font-weight: 400;
  font-size: 14px;
  line-height: 215.5%;
  color: var(--colorBlack);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 5px 0px;

  text-align: left;
  transition: var(--transitionStyle);

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.currentPage {
  color: var(--colorBlack);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--colorBlack);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--colorBlack);
    }
  }
}

.middleLinks {
  display: flex;
  align-items: center;
}

.rightLinks {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.languageButton {
  display: flex;
  margin-left: 8px;

  @media (--viewportLarge) {
    margin-left: 10px;
  }

  @media (--viewportLargeWithPaddings) {
    margin-left: 20px;
  }

  @media (--viewportSLarge) {
    margin-left: 30px;
  }

  & > button {
    background-color: rgba(255, 255, 255, 0.5);
    border: solid 1px rgba(255, 255, 255, 0.5);
    border-radius: 2px;
    padding: 2px 6px 2px 2px;
    display: inline-flex;
    align-items: center;

    &:hover,
    &:focus,
    &:active {
      background-color: rgba(255, 255, 255, 1);
      border: solid 1px rgba(255, 255, 255, 1);
    }

    @media (--viewportLarge) {
      padding: 4px 10px 4px 4px;
    }
  }
}

.programs {
  cursor: pointer;
  padding: 0;
  position: relative !important;
  bottom: 1px !important;
  & > div {
    & > div {
      &:first-of-type {
        & div {
          & svg {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }
  & > select {
    border: none;
    background: transparent;
    font-size: 14px;
    padding: 0;
    margin: 0;
    position: relative;
    color: white;
    & > option {
      color: black;
    }
  }
}

.fixedHeader {
  cursor: pointer;

  & > select {
    border: none;
    background: transparent;
    font-size: 14px;
    width: 170px;
    font-weight: var(--fontWeightBold) !important;
    color: var(--marketplaceColorSecondary) !important;
    padding: 0;
    margin: 0;
    position: relative;
    left: 20px;
  }
}
