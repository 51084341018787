@import '../../styles/customMediaQueries.css';

.root {
  /* Font */
  composes: h6 from global;
  color: var(--colorWhite);
  text-align: center;

  background-color: var(--colorFail);

  /* Layout of a red dot */
  display: inline-block;
  width: auto;
  min-width: 20px;
  height: 20px;
  border-radius: 10px;
  padding: 3px 5px 2px 5px;
  margin-left: 4px;

  /* Font margins off */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
    font-size: 12px;
    min-width: 20px;
    height: 20px;
    border-radius: 12px;
    top: 0px;
    position: relative;
  }
}
