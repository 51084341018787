@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  z-index: 0;
  display: flex;
  background: var(--colorWhite);
  border-radius: 20px;
  transition: var(--transitionStyle);
  flex-direction: column;

  @media (--viewportLargeWithPaddings) {
    flex-direction: column;
  }

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
    box-shadow: var(--boxShadow);
    transition: var(--transitionStyle);

    & .aspectRatioWrapper {
      transition: var(--transitionStyle);
      background: var(--colorWhite);

      & > div {
        transition: var(--transitionStyle);

        & > div {
          transition: var(--transitionStyle);

          & img {
            transform: scale(1.05);
            transition: var(--transitionStyle);
          }
        }
      }
    }
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyle);
  background: var(--colorGrey100);
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  & > div {
    transition: var(--transitionStyle);

    & > div {
      transition: var(--transitionStyle);

      & img {
        transition: var(--transitionStyle);
      }
    }
  }

  & .favorite {
    position: absolute;
    top: 10px;
    right: 8px;
    z-index: 1;
    background-color: hsl(144.67deg 92.28% 41.28%);
    width: 90px;
    height: 22px;
    font-size: 10px;
    color: white;
    display: flex;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    box-shadow: 0 8px 16px rgba(15, 15, 15, 0.2);
    box-shadow: 0 8px 16px rgba(15, 15, 15, 0.2);

    & > svg {
      width: 20px;
      height: 20px;

      &.iconDislike {
        width: 16px;
        height: 16px;
      }
    }
  }
  & .head {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    background-color: #0000002e;
    padding: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    & .title {
      margin-right: auto;
      padding-top: 1px;
      font-size: 16px;
      line-height: 1.5;
      font-weight: var(--fontWeightMedium);
      color: var(--colorWhite);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      width: 100%;
      @media (--viewportMedium) {
        font-size: 18px;
      }
    }
    & .price {
      display: flex;
      flex-direction: row;
      width: 100%;
      & .priceValue {
        line-height: 100%;
        font-size: 14px;
        font-weight: var(--fontWeightRegular);
        color: var(--colorWhite);
      }
      & .perUnit {
        color: var(--colorWhite);
        display: inline-flex;
        align-items: center;
        margin-left: 8px;
      }
    }
  }
}

.rating {
  position: relative;
  z-index: 1;
  background-color: var(--colorAttention);
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  font-size: 12px;
  font-weight: 400;
  line-height: 120%;
  color: var(--colorWhite);
  box-shadow: 0 8px 16px rgba(15, 15, 15, 0.2);

  & > svg {
    width: 10px;
    height: 10px;
    margin-right: 5px;

    & path {
      fill: var(--colorWhite);
    }
  }
}

.reviewSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  float: left;
  margin: 0;
  & .totalreivews {
    color: var(--colorGrey500);
    font-size: 14px;
    line-height: 120%;
    margin-left: 10px;
  }
}

.timeSec {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  margin-top: 12px;
  max-height: 85px;
  overflow-y: auto;

  @media (min-width: 480px) {
    grid-template-columns: repeat(3, 1fr);
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--marketplaceColor);
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: var(--marketplaceColorDark);
  }

  & .time {
    background-color: var(--colorWhite);
    border: solid 1px var(--colorGrey100);
    color: var(--colorGrey900);
    font-size: 13px;
    font-weight: 400;
    line-height: 100%;
    display: inline-block;
    padding: 11px 10px;
    border-radius: 4px;
    text-align: center;

    &:hover {
      border-color: var(--marketplaceColor);
      color: var(--marketplaceColor);
    }
  }
}

.otherInfo {
  & .location {
    display: flex;
    align-items: center;
    color: var(--colorGrey500);
    font-size: 14px;
    line-height: 120%;
    margin-top: 10px;

    & > span {
      color: var(--colorBlack);
      margin-right: 2px;
    }

    & > svg {
      margin-right: 8px;

      & path {
        fill: var(--colorBlack);
      }
    }
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: 4px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 16px 0 2px 0;

  & .mainInfo {
    display: flex;
    flex-direction: column;

    & .userinfo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      & .user {
        width: 24px;
        height: 24px;
        border-radius: 100px;
        overflow: hidden;

        & > a {
          width: 100%;
          height: 100%;
          background: var(--colorGrey200);

          & > span {
            font-size: 12px;
            line-height: 100%;
            font-weight: var(--fontWeightMedium);
          }
        }
      }

      & .otherinfo {
        font-size: 14px;
        line-height: 100%;
        color: var(--colorGrey800);
      }
    }

    & .authorInfo {
      font-size: 14px;
      color: var(--colorGrey800);
      line-height: 120%;
      margin-top: 0;
      margin-bottom: 0;
      display: flex;
      gap: 12px;

      & a {
        position: relative;
        bottom: 4px;
      }
    }
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.description {
  font-weight: 500;
  line-height: 18px;
  font-size: 14px;
  padding: 0;
  margin: 10px 0 0;
  color: #808282;
}

.leftDetails {
  width: 100%;
  margin-bottom: 10px;
  /* 
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  @media (--viewportMedium) {
    flex-direction: row;
  } */

  @media (--viewportLargeWithPaddings) {
    width: 100%;
    margin: 0;
  }

  & .leftImg {
    width: 100%;

    @media (--viewportMedium) {
      width: calc(100% / 3);
    }

    & .aspectRatioWrapper {
      width: 100%;
      align-self: flex-start;
    }
  }

  & .mainInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;

    & .info {
      padding-top: 0;
      float: left;
      width: 100%;

      & .head {
        & .price {
        }
      }
    }
  }
}

.pillSec {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 5px;
  float: left;
  width: 100%;
  & > span {
    color: var(--marketplaceColor);
    background-color: #f4b4882e;
    border: solid 1px var(--marketplaceColorLight);
    border-radius: 100px;
    padding: 4px 10px;
    font-size: 13px;
    line-height: 110%;
    transition: var(--transitionStyle);
    display: inline-block;
    margin: 8px 8px 0 0;

    &:hover {
      color: var(--colorBlack);
      background-color: var(--colorWhite);
      border: solid 1px var(--colorBlack);
    }
  }
}

.timeSec {
  border-top: solid 1px var(--colorGrey100);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
  padding-top: 10px;
  float: left;
  width: 100%;

  & > span {
    color: var(--marketplaceColorSecondary);
    background-color: #f4b4882e;
    border: solid 1px var(--marketplaceColorSecondary);
    border-radius: 100px;
    padding: 4px 10px;
    font-size: 12px;
    line-height: 110%;
    transition: var(--transitionStyle);
    display: inline-block;
    margin: 0;

    &:hover {
      color: var(--colorBlack);
      background-color: var(--colorWhite);
      border: solid 1px var(--colorBlack);
    }
  }
}

.availabilitySec {
  background-color: hsl(0deg 0% 97.95% / 57%);
  padding: 14px;
  border-radius: 10px;
  border: solid 1px var(--colorGrey100);
  margin: 10px 0 0;
  position: relative;
  float: left;
  width: 100%;

  & .availabilityHead {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .availabilityControl {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      & > button {
        border: solid 1px var(--colorGrey100);
        background-color: var(--colorWhite);
        width: 30px;
        height: 30px;
        border-radius: 100px;
        padding: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: var(--transitionStyle);
        position: absolute;
        top: 20px;

        &:hover {
          background-color: var(--colorWhite);
          box-shadow: var(--boxShadow);
          cursor: pointer;
          border-color: var(--marketplaceColor);
        }

        & > svg {
          width: 30px;
          height: 30px;
        }
      }

      & .leftArrow {
        transform: rotate(180deg);
        left: 10px;
      }

      & .rightArrow {
        transform: rotate(360deg);
        right: 10px;
      }
    }

    & > span {
      color: var(--colorBlack);
      font-size: 14px;
      line-height: 120%;
      padding: 0px 12px;

      @media (--viewportMedium) {
        font-size: 16px;
      }
    }
  }

  & .availabilityBody {
    padding: 0 40px;
    display: flex;

    & .dateList {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;

      & .dateItem {
        padding: 0 5px;

        & .date {
          display: flex;
          flex-direction: column;

          & > span {
            font-size: 12px;
            display: inline-flex;
            flex-direction: column;
            color: var(--colorGrey500);

            &:first-child {
              font-size: 14px;
              color: var(--colorBlack);
              font-weight: var(--fontWeightSemiBold);
            }
          }

          & .timeSlots {
            background-color: rgb(238 139 81 / 14%);
            border: solid 1px rgb(238 139 81 / 20%);
            color: var(--marketplaceColorSecondary);
            text-align: center;
            font-size: 14px;
            margin-top: 5px;

            &:first-child {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}

.noDatesFound {
  text-align: center;
  font-size: 14px;
}

.renderTimeSlots {
  color: var(--marketplaceColor);
}

.showMore {
  & > span {
    background: var(--marketplaceColor);
    color: var(--colorSuccessLight);

    &:hover {
      background: var(--marketplaceColor);
      color: var(--colorSuccessLight);
    }
  }
}

.date {
  color: #f2ab82 !important;
  background: white !important;
  border-color: #f2ab82 !important;
}
