@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  bottom: 0;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--colorGrey100);
  width: 100%;
  background-color: var(--colorGrey50);
  @media (--viewportLarge) {
    border-top: none;
  }
}
.contentWidth {
  max-width: var(--contentMaxWidthPages);
  width: 100%;
  margin: 0 auto;
}
.topSectionContent {
  background-color: var(--marketplaceColor);
  padding: 40px 24px;
  @media (--viewportMedium) {
    padding: 66px 24px;
  }
  & .contentWidth {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    @media (--viewportMedium) {
      flex-direction: row;
    }
    @media (--viewportLargeWithPaddings) {
      max-width: 90%;
    }
    & .leftSec {
      width: 100%;
      margin-bottom: 20px;
      @media (--viewportMedium) {
        width: 30%;
        margin-bottom: 0px;
      }

      @media (--viewportLargeWithPaddings) {
        width: 40%;
      }

      @media (--viewportMLarge) {
        width: 50%;
      }
    }
    & .rightSec {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @media (--viewportSmall) {
        flex-wrap: nowrap;
      }
      @media (--viewportMedium) {
        width: 70%;
      }
      @media (--viewportLargeWithPaddings) {
        width: 60%;
      }
      @media (--viewportMLarge) {
        width: 50%;
      }
      & h2 {
        font-weight: var(--fontWeightMedium);
        font-size: 16px;
        line-height: 25px;
        letter-spacing: -0.0888889px;
        color: var(--colorWhite);
        margin: 0 0 10px 0;
        padding: 0;
      }
      & .links {
        width: calc(100% / 2);
        @media (--viewportSmall) {
          width: auto;
        }
      }
    }
  }
}

.bottomSectionContent {
  background-color: var(--marketplaceColorSecondary);
  padding: 40px 24px;
  @media (--viewportMedium) {
    padding: 66px 24px;
  }
  & .contentWidth {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    @media (--viewportSmall) {
      flex-direction: row;
    }
    & > ul {
      margin: 0;
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 10px;
      justify-content: space-between;
      @media (--viewportSmall) {
        width: auto;
        margin-bottom: 0px;
        justify-content: flex-start;
      }
      & > li {
        margin-right: 18px;
        padding: 0;
        &:last-child {
          margin-right: 0;
        }
        & .legalLink {
          color: var(--colorWhite);
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          &:hover {
            color: var(--marketplaceColor);
          }
        }
      }
    }
    & .languageButton {
      position: relative;
      width: 100%;
      @media (--viewportSmall) {
        width: auto;
      }
      & > button {
        border: solid 2px var(--colorWhite);
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        padding: 10px 16px;
        & > svg {
          fill: none;
        }
      }
      & > div {
        position: absolute;
        top: auto !important;
        bottom: calc(100% + 5px);
        right: 0;
        & > div {
          display: none;
        }
      }
    }
  }
}
.newsletterSec {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;

  & .joinNowcontent {
    width: 100%;
    text-align: center;
    margin-bottom: 24px;

    & > h2 {
      font-weight: var(--fontWeightRegular);
      font-size: 20px;
      color: var(--colorWhite);
      margin: 0;
      padding: 0;
      line-height: 120%;

      @media (--viewportMedium) {
        font-size: 24px;
      }
      @media (--viewportLarge) {
        font-size: 34px;
      }
    }
    & > p {
      font-weight: var(--fontWeightRegular);
      font-size: 14px;
      line-height: 20px;
      margin: 0;
      color: var(--colorWhite);

      @media (--viewportLarge) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  & .subscribeNow {
    display: flex;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    @media (--viewportMedium) {
      width: 600px;
    }
    & .subscribe {
      display: flex;
      width: 100%;
      justify-content: space-between;
      border-radius: 5px;
      overflow: hidden;
      position: relative;
      z-index: 0;
      & > form {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-radius: 5px;
        overflow: hidden;
        & .subscribeInput {
          flex-grow: 1;
          position: relative;

          & > label {
            position: absolute;
          }
          & > input {
            background-color: var(--colorWhite);
            border-radius: 5px;
            padding: 10px 24px;
            color: #5d5a88;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            border: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            height: 100%;
            &::placeholder {
              opacity: 1;
              color: #5d5a88;
            }
            &:hover,
            &:focus,
            &:active {
              outline: none;
              border: none;
              box-shadow: none;
            }
          }
        }
        & > button {
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          background-color: var(--marketplaceColor);
          color: var(--colorWhite);
          border-radius: 5px;
          border: solid 1px var(--marketplaceColor);
          padding: 10px 30px;
          cursor: pointer;
          margin-left: -2px;
          width: auto;
          position: relative;
          z-index: 1;
          &:hover {
            background-color: var(--colorGrey700);
            border: solid 1px var(--colorGrey700);
            color: var(--colorWhite);
          }
        }
      }
    }
  }
}
.content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  @media (--viewportMedium) {
    flex-direction: row;
  }
  & .links {
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;

    @media (--viewportMedium) {
      flex-wrap: nowrap;
      margin-bottom: 0;
    }
    & .logoLink {
      display: inline-flex;
      & > svg {
        width: 120px;
        @media (--viewportLarge) {
          width: 140px;
        }
      }
    }
    & .paymentAccepted {
      margin: 10px 0;
      @media (--viewportLarge) {
        margin: 20px 0;
      }
      & > svg {
        margin-right: 10px;
        width: 40px;
        @media (--viewportLarge) {
          width: 58px;
          margin-right: 15px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    & .poweredby {
      & > a {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: var(--colorWhite);
        text-decoration: none;
      }
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: var(--colorWhite);
      margin: 0 0 10px 0;
      @media (--viewportLarge) {
        margin: 0 0 20px 0;
      }
    }
    & .copyright {
      font-weight: 400;
      font-size: 10px;
      line-height: 16px;
      color: var(--colorWhite);
      margin: 0;
    }
    & .list {
      margin-top: 0;
      margin-bottom: 0;
      & .listItem {
        min-height: 24px;
        margin-bottom: 6px;

        @media (--viewportMedium) {
          margin-bottom: 0;
        }
        & .link {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: var(--colorWhite);
          transition: var(--transitionStyleButton);

          margin-top: 0;
          margin-bottom: 12px;

          &:hover {
            color: var(--marketplaceColorSecondary);
            text-decoration: none;
          }

          @media (--viewportMedium) {
            margin-top: 0;
            margin-bottom: 0;
          }
        }
      }
    }
    & .socialLinks {
      display: flex;
      align-items: center;
      & .icon {
        width: 30px;
        height: 30px;
        margin-right: 6px;
        text-align: center;
        background-color: var(--colorWhite);
        border-radius: 100px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: var(--transitionStyle);

        @media (--viewportLargeWithPaddings) {
          margin-right: 12px;
          width: 40px;
          height: 40px;
        }
        &:hover {
          transition: var(--transitionStyle);
          background-color: var(--marketplaceColorSecondary);
        }
        &:last-child {
          margin-right: 0;
        }
        & > svg {
          width: 16px;
          height: 16px;
          @media (--viewportSmall) {
            width: 16px;
            height: 16px;
          }
          @media (--viewportLargeWithPaddings) {
            width: 30px;
            height: 30px;
          }
          & > path {
            fill: var(--marketplaceColor);
          }
          &.linkedin {
            width: 12px;
            height: 12px;
            @media (--viewportLargeWithPaddings) {
              width: 24px;
              height: 24px;
            }
            & path {
              fill: var(--marketplaceColor);
            }
          }
          &.instragram {
            width: 30px;
            height: 30px;
            @media (--viewportSmall) {
              width: 20px;
              height: 20px;
            }

            @media (--viewportLarge) {
              width: 50px;
              height: 50px;
            }
            & > path {
              &:first-child {
                fill: none;
              }
            }
          }
        }
      }
    }
  }
}
.logo {
  object-fit: contain;
  object-position: left center;
  width: 100%;
  height: 24px;
}

.legalLink,
.privacy,
.terms {
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}
