@import '../../styles/customMediaQueries.css';

.accordionMenu {
  & .sidelink {
    display: flex;
    align-items: center;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    border-radius: 12px;
    white-space: nowrap;
    font-size: 15px;
    font-weight: 600;
    line-height: 1.6;
    color: #6f767e;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: background 0.2s, box-shadow 0.2s, color 0.2s;
    cursor: pointer;

    &.sideNavSelectedIcon {
      color: var(--marketplaceColor);
      & .sideNavIcon {
        &.shopIcon {
          & > svg {
            fill: none;

            & path {
              fill: none;
              stroke: var(--marketplaceColor);
            }

            & rect {
              stroke: var(--marketplaceColor);
            }
          }
        }
      }
      &:hover {
        color: var(--marketplaceColor);
        & .sideNavIcon {
          &.shopIcon {
            & > svg {
              fill: none;

              & path {
                fill: none;
                stroke: var(--marketplaceColor);
              }

              & rect {
                stroke: var(--marketplaceColor);
              }
            }
          }
        }
      }
    }

    &:hover {
      cursor: pointer;
      color: #1a1d1f;

      & .sideNavIcon {
        &.shopIcon {
          & > svg {
            fill: none;

            & path {
              fill: none;
              stroke: #1a1d1f;
            }

            & rect {
              stroke: #1a1d1f;
            }
          }
        }

        & > svg {
          & path {
            fill: #1a1d1f;
          }
        }
      }
    }

    & .sideNavIcon {
      line-height: 100%;
      margin-right: 12px;
      margin-top: -3px;

      &.shopIcon {
        & > svg {
          fill: none;

          & path {
            fill: none;
            stroke: #6f767e;
          }

          & rect {
            stroke: #6f767e;
          }
        }
      }

      & > svg {
        width: 20px;

        & path {
          fill: #6f767e;
        }
      }
    }
  }

  & .sideDropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  & .subMenuLinks {
    padding-left: 45px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 20px;
      left: 30px;
      bottom: 32px;
      width: 2px;
      border-radius: 0;
      background: #efefef;
      height: calc(100% - 40px);
    }
  }

  & .accordionMenuitem {
    cursor: pointer;

    & .accordionItemHead {
      & .accordionItemBtn {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &::after {
          display: inline-block;
          content: '';
          height: 10px;
          width: 10px;
          margin-right: 12px;
          border-bottom: 2px solid currentColor;
          border-right: 2px solid currentColor;
          transform: rotate(-136deg);
        }

        &[aria-expanded='true'],
        &[aria-selected='true'] {
          &::after {
            transform: rotate(45deg);
          }
        }
      }
    }
  }
}

.dashboardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  & .mobileSideBar {
    position: relative;
  }
}

.disabled {
  cursor: not-allowed !important;
}

.notificationDot {
  background: var(--colorBlack);
  color: white;
  padding: 5px;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  position: relative;
  bottom: 7px;
}
