@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.formRow {
  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  width: 100%;
  margin-top: 14px;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    padding: 0;
  }

  &:first-child {
    margin-top: 0;
  }
}

.field {
  display: flex;
  align-items: flex-end;
  position: relative;
  width: 100%;

  &:nth-child(2n) {
    margin-top: 12px;
  }

  @media (--viewportMedium) {
    width: calc(50% - 12px);

    &:nth-child(2n) {
      margin-top: unset;
    }
  }
}

.fieldDisabled {
  background: none;
  color: --colorGrey100;
}

.fieldDateInput {
  flex-grow: 1;
}

.fieldSelect {
  flex-grow: 1;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 13px;
    left: 11px;
    background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" xmlns="http://www.w3.org/2000/svg"><g transform="translate(1 -3)" fill="none" fill-rule="evenodd"><circle stroke="%234A4A4A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" cx="6" cy="10" r="6"/><g transform="translate(2 6)" fill="%234A4A4A"><rect x="3.5" width="1" height="5" rx=".5"/><rect transform="rotate(90 2.5 4.5)" x="2" y="2.5" width="1" height="4" rx=".5"/></g></g></svg>');
    background-size: 12px 12px;
    width: 12px;
    height: 12px;
  }

  @media (--viewportMedium) {
    &::after {
      left: 11px;
      bottom: 19px;
    }
  }
}

.fieldSelectDisabled {
  composes: fieldSelect;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 13px;
    left: 11px;
    background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" xmlns="http://www.w3.org/2000/svg"><g transform="translate(1 -3)" fill="none" fill-rule="evenodd"><circle stroke="%23E6E6E6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" cx="6" cy="10" r="6"/><g transform="translate(2 6)" fill="%23E6E6E6"><rect x="3.5" width="1" height="5" rx=".5"/><rect transform="rotate(90 2.5 4.5)" x="2" y="2.5" width="1" height="4" rx=".5"/></g></g></svg>');
    background-size: 12px 12px;
    width: 12px;
    height: 12px;
  }
  @media (--viewportMedium) {
    &::after {
      left: 11px;
      bottom: 19px;
    }
  }
}

.select {
  position: relative;
  padding-left: 30px;
  padding-right: 16px;
  background-image: url('data:image/svg+xml;utf8,<svg width="10" height="6" xmlns="http://www.w3.org/2000/svg"><path d="M4.686 5.315a.445.445 0 00.628 0L8.87 1.759a.444.444 0 10-.629-.628L5 4.37 1.759 1.13a.444.444 0 10-.628.628l3.555 3.556z" stroke="%234A4A4A" fill="%234A4A4A" fill-rule="evenodd"/></svg>');
  background-size: 10px 6px;

  @media (--viewportMedium) {
    padding-left: 32px;
  }
}

.selectDisabled {
  composes: select;
  background-image: url('data:image/svg+xml;utf8,<svg width="10" height="6" xmlns="http://www.w3.org/2000/svg"><path d="M4.686 5.315a.445.445 0 00.628 0L8.87 1.759a.444.444 0 10-.629-.628L5 4.37 1.759 1.13a.444.444 0 10-.628.628l3.555 3.556z" fill="%23E6E6E6" stroke="%23E6E6E6" fill-rule="evenodd"/></svg>');
}

.arrowIcon {
  stroke: var(--colorWhite);
  fill: var(--colorWhite);
}

/**
CSS for hiding the end date

If you want to show the field for the end date e.g. for enabling longer bookings,
you can remove the following code.
Remember to do required code changes also in FieldDateAndTimeInput.js!
**/

.field {
  width: calc(50% - 34px);

  @media (--viewportMedium) {
    width: calc(50% - 12px);
  }
}

.startDate {
  width: 100%;
}
.endDateHidden {
  display: none;
}

.lineBetween,
.lineBetweenDisabled {
  margin: 33px 6px 0 6px;

  @media (--viewportMedium) {
    margin: 40px 8px 0 8px;
  }
}
.lineBetweenDisabled {
  color: var(--colorGrey100);
}

/**
CSS for hiding the end date above
**/

.bookingDatePicker {
  & .formRow {
    & .field {
      & .fieldDateInput {
        & > label {
          color: var(--colorGrey500);
          font-size: 15px;
          font-weight: var(--fontWeightRegular);
          line-height: 120%;
          padding: 0;
          margin: 0 0 5px 0;
        }
        & > div {
          margin-bottom: 5px;
          & input {
            padding-left: 45px;
            border-radius: 6px;
            background-position: left 14px center;
            font-size: 14px;
            font-weight: 400;
            min-height: 48px;
          }
        }
        & :global(.SingleDatePicker_picker) {
          top: 100% !important;
        }
      }
    }
  }
}
.timeSlotBlocks {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 8px;
  text-align: center;
  overflow-y: auto;
  max-height: 80px;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--marketplaceColor);
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: var(--marketplaceColorDark);
  }
  @media (--viewportSmall) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (--viewportMedium) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (--viewportLarge) {
    grid-template-columns: repeat(3, 1fr);
  }
  & .timeBlock {
    cursor: pointer;
    padding: 8px 16px;
    margin: 0;
    position: relative;
    z-index: 0;
    border: solid 1px #a8a8a8;
    border-radius: 100px;
    transition: var(--transitionStyle);
    background-color: var(--colorWhite);
    display: inline-block;
    line-height: 100%;
    font-size: 14px;
    color: #a8a8a8;
    font-weight: 400;
    transition: var(--transitionStyle);
    &:hover,
    &.activeSlot {
      transition: var(--transitionStyle);
      border: solid 1px var(--marketplaceColor);
      cursor: pointer;
      color: var(--marketplaceColor);
      transition: var(--transitionStyle);
    }
  }
}
