@import '../../styles/customMediaQueries.css';

.root {
  display: block;
  flex-shrink: 0;

  &:hover {
    text-decoration: none;
  }
}

.logo {
  max-width: 171px;
  width: 100%;
  object-fit: contain;
  object-position: center;
}
